
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Publication } from '../types'
import { parseReview } from '@/utils/utils'
import FullBibIssueFormat from '@/components/bib-formatting/FullBibIssueFormat.vue'

@Component({
  components: {
    FullBibIssueFormat,
  },
})
export default class PublicGenericFormat extends Vue {
  @Prop() item!: Publication
  @Prop({ default: false }) internalAccess!: boolean
  showArticleModal = false
  //
  // COMPONENT PROPERTIES
  //

  get articleRoute() {
    // return {
    //   name: 'RelayStation',
    //   query: {
    //     // mr: this.item.mrnumber.toString(),
    //     mr: this.formattedMRNumber,
    //   },
    // }
    return `https://mathscinet.ams.org/mathscinet-getitem?mr=${this.formattedMRNumber}`
  }

  get formattedMRNumber() {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - this.item.mrnumber.toString().length
    return '0'.repeat(leadingZeros) + this.item.mrnumber
  }

  get hasArticle() {
    return this.item.articleUrl !== '' || (this.item.openURL && this.item.openURL.targetLink.length > 0)
  }

  get title() {
    return parseReview(this.item.titles.title)
  }

  get translatedTitle() {
    return parseReview(this.item.titles.translatedTitle)
  }

  // get volumeFormatted() {
  //   if (!this.item.issue.volume) {
  //     return ''
  //   }
  //   // {{ item.issue.volume }}<span v-if="item.issue.volume2">/{{ item.issue.volume2 }}</span><span v-if="item.issue.volume3">/{{ item.issue.volume3 }}</span>
  //   let result = `${this.item.issue.volume}`
  //   if (this.item.issue.volume2) {
  //     if (this.item.issue.volSlash.toLowerCase() === 'p') {
  //       result = `${result}(${this.item.issue.volume2})`
  //     } else {
  //       result = `${result}/${this.item.issue.volume2}`
  //     }
  //   }
  //   if (this.item.issue.volume3) {
  //     if (this.item.issue.volSlash.toLowerCase() === 'p') {
  //       result = `${result}(${this.item.issue.volume3})`
  //     } else {
  //       result = `${result}/${this.item.issue.volume3}`
  //     }
  //   }
  //   return result
  // }

  toggleShowingFull() {
    const reviewEl = this.$refs.reviewDiv as HTMLElement
    if (reviewEl.classList.contains('fade-bottom')) {
      reviewEl.classList.remove('fade-bottom')
    } else {
      reviewEl.classList.add('fade-bottom')
    }
  }
}
